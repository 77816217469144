<template>
    <div>

        <div class="row section-header" >
            <div class="col-md-3 section-title">
                <h3>Prodotti</h3>
            </div>

            <div class="col-md-6">

            </div>

            <div class="col-md-3 text-right" style="">
            </div>
        </div>

        <div class="top-content text-right">
            <router-link to="/activate" class="btn btn-warning">Attiva un nuovo prodotto</router-link>
        </div>

        <div class="content">
            <div class="index" :class="{ 'opened': (currentId == -1) }">
                <div >
                    <div class="row">
                        <div class="col-sm-3 text-center"
                            v-for="item in licenses"
                            :key="item.id">
                            <div class="card product-button" style="margin-bottom: 25px" :class="{disabled: (item.hasExtended && isExpired(item) )}" v-if="item.info">
                                <a :href="item.url" class="btn">
                                    <span class="product-icon" :style="{backgroundImage: 'url(https://edulab.toccafondimultimedia.com/'+item.info.icon}">
                                        <img src="../../assets/square-transparent.png" :alt="item.info.name" class="img-fluid"/>
                                    </span>
                                </a>
                                <span class="text-muted font-weight-bold small">{{ item.school.name }}</span>
                                <span class="text-danger font-weight-bold " v-if="isExpired(item)">LICENZA SCADUTA</span>
                                <span class="text-warning font-weight-bold " v-else-if="isExpiring(item)">IN SCADENZA<br> ultimi {{ remainingDays(item) }} giorni disponibili</span>
                                <span class="text-success font-weight-bold " v-else>ATTIVATA</span>

                                <span class="text-info small" v-if="isExpired(item) && !item.hasExtended">clicca per estendere la tua licenza di 4 giorni</span>
                            </div>
                        </div>
                    </div>
                    <p v-if="this.licenses.length == 0">Ancora nessuna licenza attivata</p>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import ProductLicenseDataService from "@/components/product_license/ProductLicenseDataService";
import moment from 'moment';
import EduLabDataService from "@/components/product_license_template/EduLabDataService";
import {ParseUrl} from "@/helpers/ParseUrl";

export default {
    name: "product-license-profile-index",
    data() {
        return {
            licenses: [],
            currentLicense: null,
            currentId: -1,
            edit: false,
            generate: false
        };
    },
    methods: {
        retrieveLicenses() {
            EduLabDataService.getAllProducts()
                .then(response => response.json())
                .then(data => {
                    let products = data;
                    ProductLicenseDataService.getAllByProfile(this.$store.state.user.profileId)
                        .then(response => {
                            this.licenses = response.data;
                            //console.log(this.licenses)
                            for (let license of this.licenses) {
                                license.info = products.find(p => p.id == license.edulabProductId)
                                if (!this.isExpired(license)) {
                                    license.url = EduLabDataService.edulabBaseUrl
                                        +'?productId='+license.edulabProductId
                                        +'&schoolId='+license.school.id
                                        +'&token='+this.$store.state.token;
                                } else if (!license.hasExtended) {
                                    license.url = '/product-license-extend/' + license.code;
                                } else {
                                    license.url = 'javascript: return false';
                                }

                            }
                        })
                        .catch(e => {
                            console.log(e);
                        });
                })
                .catch(e => {
                    console.log(e);
                });
        },
        searchName(name) {
            this.currentLicense = null;
            this.currentId = -1;
            ProductLicenseDataService.findByName(name)
                .then(response => {
                    this.licenses = response.data;
                })
                .catch(e => {
                    console.log(e);
                });
        },
        formatDate(datetime) {
            if (datetime) return moment(datetime).format('DD/MM/YYYY HH:mm');
            else return '';
        },
        isExpired(item) {
            return (moment().isAfter(item.expireAt));
        },
        isExpiring(item) {
            //console.log('asdf', item.expireAt);
            //console.log(moment(item.expireAt).subtract(10, 'days').format());
            // TODO: testare che funzioni bene.
            return (moment().isAfter(moment(item.expireAt).subtract(10, 'days')));
        },
        remainingDays(item) {
            return moment(item.expireAt).diff(moment(), 'days');
        }
    },
    mounted() {
        const parsedUrl = ParseUrl();
        if (parsedUrl.queryParams.has('edulabProductId')) {
            console.log();
            // check if user has the product license active
            ProductLicenseDataService.hasActiveLicenseByProfile(parsedUrl.queryParams.get('edulabProductId'))
                .then(response => {
                    if (response.data.length > 0) {
                        let schoolId = response.data[0].schoolId;
                        // license is active
                        // redirect to edulab resource
                        location.href = EduLabDataService.edulabBaseUrl + parsedUrl.query + '&schoolId='+schoolId + '&token='+this.$store.state.token;
                    } else {
                        // license not registered or expired
                        this.$router.push('/product-denied');
                    }
                })
                .catch(e => {
                    this.errorMsg = e.response.data.message;
                    console.log(e);
                });
        }
        this.retrieveLicenses();
    },
};
</script>

<style scoped>
    .product-icon {
        display: block;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .product-icon img {
        width: 100%;
    }
    .product-button:hover {
        cursor: pointer;
    }
    .disabled {
        cursor: not-allowed!important;
        opacity: .7;
    }
    .disabled a {
        cursor: not-allowed!important;
    }
</style>
